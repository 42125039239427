


































































































































































import { Observer } from 'mobx-vue'
import { Component, Inject, Ref, Vue } from 'vue-property-decorator'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/swiper-bundle.css'
import { AppProvider } from '@/app-providers'
import { IdoPoolsViewModel } from '../viewmodels/ido-pools-viewmodel'

@Observer
@Component({
  components: {
    Swiper,
    SwiperSlide,
    PoolCover: () => import('@/components/images/pool-cover.vue'),
  },
})
export default class TrailerVideos extends Vue {
  @Inject() providers!: AppProvider
  @Inject() vm!: IdoPoolsViewModel
  @Ref('trailerVideo') trailerVideo: any
  @Ref('swiper') swiper: any
  isMuted = true
  playAudio() {
    this.isMuted = !this.isMuted
  }
  swiperOption = {
    mousewheel: true,
    slidesPerView: 1,
    centeredSlides: true,
    pagination: { clickable: true },

    on: {
      activeIndexChange: (item) => {
        this.vm.changeActiveVideo(item.activeIndex)
      },
    },
  }

  goToProjectDetail() {
    this.$router.push(`/project/${this.vm.activeTrailerPool.pool.unicodeName}`)
  }
  activeIndexNext() {
    this.swiper.$swiper.slideNext()
  }
  activeIndexBack() {
    this.swiper.$swiper.slidePrev()
  }
}
